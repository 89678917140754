import React, { FC, useContext, useEffect, useRef, useState } from 'react'
import * as pageUtils from '@pages/articles/__[id].utils'
import { PageTitle } from '@components/configs/PageTitle'
import SectionTitle from '@components/ui/sectionTitle'
import { Article } from '@services/models/articles.model'
import {
	AppContextProps,
	AppStateContext
} from '@components/layouts/DynamicLayout'
import Loader from '@components/ui/loader'
import { Interweave } from 'interweave'
import ReactToPrint from 'react-to-print'
import { formatStrapiText } from '@utils/methods'
import OutsideClickHandler from 'react-outside-click-handler'
import { Collapse } from 'react-collapse'
import {
	FacebookShareButton,
	TwitterShareButton,
	LinkedinShareButton
} from 'react-share'
import { Icon } from '@components/ui/Icon'
import {
	twitterBlackIcon,
	facebookBlackIcon,
	linkedinBlackIcon,
	printIcon,
	shareIcon,
	printWhiteIcon,
	shareWhiteIcon
} from '@images/icons'
import { PageProps } from 'gatsby'
import { joinClasses } from '@utils/styles'
import { Request, RequestTheme } from 'typings/shared'
import config from '@utils/config'
import Text from '@components/ui/text'
import BlueBulletsList, {
	BlueBulletsLabel
} from '@components/ui/blueBulletsList'

const ArticlePage: FC<PageProps<null, null, pageUtils.LocationState>> = ({
	params: { id },
	location
}) => {
	const { pageData, language, setCurrentArticle } =
		useContext<AppContextProps>(AppStateContext)

	const [article, setArticle] = useState<Article>()
	const [loaderTime, setLoaderTime] = useState<number>(100)
	const [isLoading, setIsLoading] = useState<boolean>(true)
	const [openShareButtons, setOpenShareButtons] = useState<boolean>(false)
	const [infoRequestsBlueBulletsLabel, setInfoRequestsBlueBulletsLabel] =
		useState<BlueBulletsLabel[]>([])
	const [reportsRequestsBlueBulletsLabel, setReportsRequestsBlueBulletsLabel] =
		useState<BlueBulletsLabel[]>([])

	const printContentRef = useRef<HTMLDivElement>(null)

	const fetchArticle = async () => {
		const articleFetch: Article =
			location.state?.articleState && location.state?.articleState.content
				? location.state.articleState
				: await Article.fetchArticleByIdAsync(id)

		setArticle(articleFetch)
		setCurrentArticle(articleFetch)

		// loading too fast, this will help a little.
		setTimeout(() => {
			setIsLoading(false)
		}, loaderTime)
	}

	const onCloseShareButtons = () => setOpenShareButtons(false)

	const onToggleShareButtons = () => setOpenShareButtons(!openShareButtons)

	const populateSidebarRequestContent = () => {
		const informationRequest: RequestTheme | undefined =
			pageData?.collections?.requestThemes.find(
				(requestTheme) => requestTheme.name === 'informationsRequests'
			)
		const reports: RequestTheme | undefined =
			pageData?.collections?.requestThemes.find(
				(requestTheme) => requestTheme.name === 'reports'
			)
		const reportsRequestTmp: Request[] = []
		const informationRequestTmp: Request[] = []
		const infoBlueBullets: BlueBulletsLabel[] = []
		const reportBlueBullets: BlueBulletsLabel[] = []

		const relatedRequests: pageUtils.RelatedRequest[] =
			config.articles.relatedRequests

		informationRequest?.requestSubThemes.forEach((subTheme) => {
			const requestFound: Request[] = subTheme.requests?.filter((request) => {
				let id: string = request.requestId
				const relatedRequestFound: pageUtils.RelatedRequest[] =
					relatedRequests.filter(
						(relatedRequest) => relatedRequest.relatedRequestId === id
					)

				if (relatedRequestFound.length > 0) {
					let isRequestIdInArticle: boolean = false
					for (const requestRelated of relatedRequestFound) {
						if (article?.requestIds.includes(requestRelated.requestId)) {
							isRequestIdInArticle = true
						}
					}

					return isRequestIdInArticle
				}

				return article?.requestIds.includes(id)
			})

			if (requestFound.length > 0) {
				informationRequestTmp.push(...requestFound)
			}
		})

		infoBlueBullets.push(
			...informationRequestTmp.map(
				(request: Request) =>
					({
						name: request.title,
						link: getURLRequestForm(request.requestId)
					} as BlueBulletsLabel)
			)
		)

		setInfoRequestsBlueBulletsLabel(infoBlueBullets)

		reports?.requestSubThemes.forEach((subTheme) => {
			const requestFound: Request[] = subTheme.requests?.filter((request) => {
				let id: string = request.requestId
				const relatedRequestFound: pageUtils.RelatedRequest[] =
					relatedRequests.filter(
						(relatedRequest) => relatedRequest.relatedRequestId === id
					)

				if (relatedRequestFound.length > 0) {
					let isRequestIdInArticle: boolean = false
					for (const requestRelated of relatedRequestFound) {
						if (article?.requestIds.includes(requestRelated.requestId)) {
							isRequestIdInArticle = true
						}
					}

					return isRequestIdInArticle
				}

				return article?.requestIds.includes(id)
			})

			if (requestFound.length > 0) {
				reportsRequestTmp.push(...requestFound)
			}
		})

		reportBlueBullets.push(
			...reportsRequestTmp.map(
				(request: Request) =>
					({
						name: request.title,
						link: getURLRequestForm(request.requestId)
					} as BlueBulletsLabel)
			)
		)

		setReportsRequestsBlueBulletsLabel(reportBlueBullets)
	}

	const getURLRequestForm = (requestId: string): string => {
		return `${config.request.create.baseURL}/${requestId}`
	}

	const clearArticlePage = () => {
		setArticle(undefined)
		setCurrentArticle(undefined)
	}

	useEffect(() => {
		populateSidebarRequestContent()
	}, [article, language])

	useEffect(() => {
		setLoaderTime(200)
		setIsLoading(true)
		fetchArticle()
	}, [id])

	useEffect(() => {
		setLoaderTime(100)
		fetchArticle()
		return () => {
			clearArticlePage()
		}
	}, [])

	return (
		<>
			<PageTitle title={article?.title || pageData.title} />
			{isLoading && (
				<div className={pageUtils.classes.root}>
					<section
						className={joinClasses([pageUtils.classes.section, 'loading-page'])}
					>
						<Loader text={pageData?.assets?.loading} />
					</section>
				</div>
			)}

			{!isLoading && article && (
				<>
					<SectionTitle title={article.title} />
					<div className={pageUtils.classes.root}>
						<section className={pageUtils.classes.section}>
							<div className={pageUtils.classes.sectionContent}>
								<div className={pageUtils.classes.buttonsContainer}>
									<ReactToPrint
										trigger={() => (
											<button className={pageUtils.classes.button}>
												<Icon src={printIcon} className="img-no-hover" />
												<Icon src={printWhiteIcon} className="img-hover" />
												{formatStrapiText(
													pageData?.assets?.article_sidebar_print
												)}
											</button>
										)}
										content={() => printContentRef.current}
									/>

									<div className={pageUtils.classes.shareContainer}>
										<button
											onClick={onToggleShareButtons}
											className={pageUtils.classes.button}
										>
											<Icon src={shareIcon} className="img-no-hover" />
											<Icon src={shareWhiteIcon} className="img-hover" />
											{formatStrapiText(
												pageData?.assets?.article_sidebar_share
											)}
										</button>
										<OutsideClickHandler onOutsideClick={onCloseShareButtons}>
											<div className={pageUtils.classes.shareListIcons}>
												<Collapse isOpened={openShareButtons}>
													<FacebookShareButton
														url={location.toString()}
														quote={location.href}
													>
														<Icon src={facebookBlackIcon} /> Facebook
													</FacebookShareButton>

													<TwitterShareButton
														title={article.title}
														url={location.href}
													>
														<Icon src={twitterBlackIcon} /> Twitter
													</TwitterShareButton>

													<LinkedinShareButton url={location.href}>
														<Icon src={linkedinBlackIcon} /> Linkedin
													</LinkedinShareButton>
												</Collapse>
											</div>
										</OutsideClickHandler>
									</div>
								</div>
								<div
									className={pageUtils.classes.content}
									ref={printContentRef}
								>
									<h2 className="title">{article.title}</h2>
									<Interweave
										filters={[pageUtils.filterContent]}
										content={article.content}
									/>
									<div className={pageUtils.classes.mention}>
										{formatStrapiText(pageData?.assets?.article_mention_footer)}
									</div>
								</div>
							</div>
						</section>
						<div className={pageUtils.classes.sidebar}>
							<div className={pageUtils.classes.sidebarTitle}>
								{formatStrapiText(pageData?.assets?.article_sidebar_request)}
							</div>
							<div className={pageUtils.classes.sidebarContent}>
								{infoRequestsBlueBulletsLabel.length === 0 &&
									reportsRequestsBlueBulletsLabel.length === 0 && (
										<>{pageData?.assets?.article_sideContent_noLinkedRequest}</>
									)}

								{infoRequestsBlueBulletsLabel.length > 0 && (
									<div className={pageUtils.classes.sidebarContentRequest}>
										<Text
											content={
												pageData?.assets?.article_sideContent_infoRequest
											}
										/>

										<BlueBulletsList labels={infoRequestsBlueBulletsLabel} />
									</div>
								)}

								{reportsRequestsBlueBulletsLabel.length > 0 && (
									<div className={pageUtils.classes.sidebarContentRequest}>
										<Text
											content={
												pageData?.assets?.article_sideContent_reportRequest
											}
										/>

										<BlueBulletsList labels={reportsRequestsBlueBulletsLabel} />
									</div>
								)}
							</div>
						</div>
					</div>
				</>
			)}
		</>
	)
}

export default ArticlePage
