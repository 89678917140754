import React, { FC, MouseEvent, useEffect } from 'react'
import { joinClasses, makeClasses } from '@utils/styles'
import { Colors } from '@utils/css-variables'
import { chevronBlueIcon } from '@images/icons'
import Link from '@components/ui/link'
import { formatHtmlForStrapiText, formatStrapiText } from '@utils/methods'
import Text from '@components/ui/text'
import Authentication from '@components/auth/Authentication'

export type BlueBulletsLabel = {
	name: string
	link?: string
}

type propsTypes = {
	labels?: BlueBulletsLabel[]
	text?: boolean
	children?: React.ReactNode
	strapyLink?: boolean
}

type ClassType = {
	list: string
	textList: string
	after: string
	container: string
}

const classes: ClassType = makeClasses({
	container: {
		'& ul': {
			padding: '20px 30px 20px 40px',
			listStyle: 'none',
			fontSize: '15px',
			marginBottom: 0,
			marginTop: '10px',
			'& > li': {
				position: 'relative',
				marginBottom: 10,
				fontSize: 16,
				fontWeight: 'normal',
				lineHeight: '20px',
				color: Colors.lightBlack,
				verticalAlign: 'baseline'
			},
			'& > li a': {
				color: Colors.secondaryLighten1,
				textDecoration: 'none',
				fontWeight: 'normal'
			},
			'& > li a:visited': {
				textDecoration: 'none',
				color: Colors.secondary
			},
			'& > li a:hover': {
				textDecoration: 'underline'
			}
		}
	},
	textList: {
		'& ul': {
			padding: '0px 0px 0px 15px',
			'& li::before': {
				content: "''",
				position: 'absolute',
				width: '4px',
				height: '4px',
				top: '7px',
				left: '2px',
				borderRadius: '50%',
				backgroundColor: Colors.secondary,
				marginLeft: '-15px'
			}
		}
	},
	after: {
		'& ul': {
			'& > li::after': {
				content: "' '",
				position: 'absolute',
				top: '2px',
				left: '-15px',
				borderRadius: 0,
				width: '10px',
				height: '15px',
				backgroundImage: `url(${chevronBlueIcon})`,
				backgroundSize: '20px',
				backgroundRepeat: 'no-repeat',
				backgroundPosition: 'center center',
				verticalAlign: 'baseline',
				color: Colors.secondaryLighten1,
				backgroundColor: 'transparent'
			}
		}
	}
})

const BlueBulletsList: FC<propsTypes> = ({
	labels,
	text,
	children,
	strapyLink
}) => {
	const { authenticationRedirectSignIn } = Authentication()

	const addLinkAttribute = () => {
		document
			.querySelector(`.${classes.container}`)
			?.querySelectorAll('a')
			.forEach((link) => {
				if (!link?.href?.includes('#')) {
					link?.setAttribute('target', '_blank')
				}
			})
	}

	const onNavigateToLink = (link: string) => (e) => {
		if (link.startsWith('/')) {
			e.preventDefault()

			authenticationRedirectSignIn(link)

			return
		}
	}

	useEffect(() => {
		addLinkAttribute()
	}, [])

	if (labels) {
		return (
			<div
				className={joinClasses([
					classes.container,
					text ? classes.textList : '',
					!text ? classes.after : '',
					'blue-bullets-list'
				])}
			>
				<ul>
					{labels!.map((label, index) => (
						<li key={index}>
							{text ? (
								formatStrapiText(label.name)
							) : strapyLink ? (
								<Text content={formatHtmlForStrapiText(label.name)} />
							) : (
								<Link
									to={label.link || `#${label.name}`}
									onClick={onNavigateToLink(label.link || `#${label.name}`)}
								>
									{formatStrapiText(label.name)}
								</Link>
							)}
						</li>
					))}
				</ul>
			</div>
		)
	}

	return (
		<div
			className={joinClasses([
				classes.container,
				text ? classes.textList : '',
				!text ? classes.after : '',
				'blue-bullets-list'
			])}
		>
			{children}
		</div>
	)
}

export default BlueBulletsList
