import { makeClasses } from '@utils/styles'
import { ButtonStyle, Colors, Indexes } from '@utils/css-variables'
import { FilterInterface } from 'interweave'
import Breakpoints from '@utils/breakpoints'
import { Article } from '@services/models/articles.model'

export type RelatedRequest = {
	requestId: string
	relatedRequestId: string
}

export type LocationState = {
	articleState: Article
}

type ClassType = {
	root: string
	section: string
	sectionContent: string
	button: string
	content: string
	mention: string
	sidebar: string
	sidebarTitle: string
	sidebarContent: string
	shareContainer: string
	shareListIcons: string
	shareItemIcon: string
	buttonsContainer: string
	sidebarContentRequest: string
}

export const classes: ClassType = makeClasses({
	root: {
		display: 'flex',
		flexWrap: 'wrap',
		width: '100%',
		maxWidth: '100%',
		padding: '40px',
		[Breakpoints.maxWidth('xl')]: {
			padding: '30px 15px'
		},
		[Breakpoints.maxWidth('sm')]: {
			padding: '20px 0'
		}
	},
	section: {
		padding: '0 15px',
		flex: '0 0 calc((8/12)*100%)',
		maxWidth: 'calc((8/12)*100%)',
		'&.loading-page': {
			flex: '0 0 100%',
			maxWidth: '100%'
		},
		[Breakpoints.maxWidth('xl')]: {
			flex: '0 0 calc((7/12)*100%)',
			maxWidth: 'calc((7/12)*100%)'
		},
		[Breakpoints.maxWidth('lg')]: {
			flex: '0 0 100%',
			maxWidth: '100%',
			marginBottom: '30px'
		},
		[Breakpoints.maxWidth('sm')]: {
			marginBottom: '20px',
			padding: 0
		},
		'& > *': {
			fontFamily: 'Roboto,Arial,Helvetica,sans-serif!important',
			fontSize: '16px!important',
			lineHeight: '1.62em'
		},
		p: {
			margin: 0
		},
		'.hide-content': {
			display: 'none'
		},
		'.content': {
			margin: '10px 0'
		},
		'.link': {
			color: Colors.secondary,
			textDecoration: 'none',
			'&:hover': {
				textDecoration: 'underline'
			}
		}
	},
	sectionContent: {
		background: Colors.white,
		padding: '40px',
		[Breakpoints.maxWidth('md')]: {
			padding: '20px'
		}
	},
	button: {
		...ButtonStyle,
		display: 'flex',
		alignItems: 'center',
		fontSize: '12px',
		color: Colors.secondary,
		padding: '10px 20px',
		marginRight: '10px',
		'.img-no-hover': {
			display: 'inline-flex'
		},
		'.img-hover': {
			display: 'none'
		},
		img: {
			width: '15px',
			marginRight: '5px'
		},
		'&:hover': {
			cursor: 'pointer',
			background: Colors.darkBlue2,
			color: Colors.white,
			borderColor: Colors.darkBlue2,
			'.img-no-hover': {
				display: 'none'
			},
			'.img-hover': {
				display: 'inline-flex'
			}
		}
	},
	content: {
		'.title': {
			display: 'none'
		},
		'@media print': {
			color: Colors.black,
			'.title': {
				display: 'block'
			},
			padding: '40px'
		}
	},
	mention: {
		borderTop: `1px solid ${Colors.grey}`,
		paddingTop: '25px',
		marginTop: '25px',
		fontSize: '14px',
		color: Colors.darkGrey,
		'@media print': {
			color: Colors.darkGrey
		}
	},
	sidebar: {
		padding: '0 15px',
		flex: '0 0 calc((4/12)*100%)',
		width: '100%',
		maxWidth: 'calc((4/12)*100%)',
		[Breakpoints.maxWidth('xl')]: {
			flex: '0 0 calc((5/12)*100%)',
			maxWidth: 'calc((5/12)*100%)'
		},
		[Breakpoints.maxWidth('lg')]: {
			flex: '0 0 100%',
			maxWidth: '100%'
		}
	},
	sidebarTitle: {
		background: Colors.secondary,
		color: Colors.white,
		fontWeight: 600,
		padding: '20px 30px',
		[Breakpoints.maxWidth('lg')]: {
			padding: '20px 40px'
		},
		[Breakpoints.maxWidth('md')]: {
			padding: '20px'
		}
	},
	sidebarContent: {
		background: Colors.white,
		padding: '30px',
		ul: {
			marginTop: '0!important',
			fontSize: '16px!important',
			padding: '10px 0 20px 15px!important'
		},
		[Breakpoints.maxWidth('lg')]: {
			padding: '30px 40px'
		},
		[Breakpoints.maxWidth('md')]: {
			padding: '20px'
		}
	},
	sidebarContentRequest: {
		p: {
			marginTop: 0
		}
	},
	shareContainer: {
		position: 'relative'
	},
	shareListIcons: {
		position: 'absolute',
		top: '100%',
		left: 0,
		width: '135px',
		background: Colors.white,
		zIndex: Indexes.index1,
		button: {
			display: 'flex',
			alignItems: 'center',
			width: '100%',
			padding: '8px 12px!important',
			color: Colors.black,
			img: {
				width: '20px',
				marginRight: '5px'
			},
			'&:hover': {
				backgroundColor: `${Colors.darkWhite}!important`
			}
		}
	},
	buttonsContainer: {
		display: 'flex',
		alignItems: 'center',
		marginBottom: '25px'
	}
})

export const filterContent: FilterInterface = {
	node(name, node) {
		if (name === 'p') {
			node.classList.add('content')

			if (node.textContent === '') {
				node.classList.add('hide-content')
			}
		}

		const websiteUrl: string = location?.origin || ''

		if (name === 'a') {
			node.classList.add('link')

			if (!node.getAttribute('href')?.startsWith(websiteUrl)) {
				node.setAttribute('target', '_blank')
			}
		}

		if (name === 'br') {
			node.classList.add('hide-content')
		}

		node.removeAttribute('style')

		return node
	}
}
